import getData from "@/__main__/get-data.mjs";
import ValorantGuides from "@/data-models/valorant-guides.mjs";
import { getGuides } from "@/game-val/api.mjs";
import fetchConstants from "@/game-val/fetch-constants-data.mjs";
import {
  getGuideKeyStr,
  getParamsObjForAgent,
} from "@/game-val/guides-utils.mjs";

export default async function fetchData(tab, searchParams) {
  const [, agents] = await fetchConstants();
  agents.list = agents.list.filter((o) => !o.key.includes("/"));

  if (tab) {
    const [agent] = tab;

    await getData(
      getGuides(getParamsObjForAgent(searchParams, agent)),
      ValorantGuides,
      [
        "val",
        "guides",
        getGuideKeyStr(getParamsObjForAgent(searchParams, agent), true),
      ],
      {
        mergeFn: (curValue, newValue) => {
          if (newValue.page > curValue.page) {
            newValue.list = [...curValue.list, ...newValue.list];
          }
          return newValue;
        },
      },
    );
  }
  return agents;
}
